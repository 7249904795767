import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import {
    ProcessPaymentService,
    ProofOfPayment,
    DEFAULT_PROOF_OF_PAYMENT,
    AddedProofOfPayment } from '../../../services/process-payment/process-payment.service';
import * as moment from 'moment';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PATH_PROCESS } from '../../../services/appdata/app-config.service';
import { forkJoin } from 'rxjs';

interface AddedProofOfPaymentHandle {
    data: AddedProofOfPayment;
    state: {
        isVisible: boolean;
        isSelected: boolean;
        isOpen: boolean;
    };
}


@Component({
    selector: 'app-proof-of-payments',
    templateUrl: './proof-of-payment.component.html',
    styleUrls: ['./proof-of-payment.component.scss']
})
export class ProofOfPaymentsComponent implements OnInit {

    data: any = [];
    addedProofOfPayment: AddedProofOfPaymentHandle[] = [];

    isVisible = false;
    showDeleteProofOfPayments = false;
    proofOfPaymentSelected: any = [];
    proofOfPaymentId = 0;
    showDeleteActions = false;
    showAttachmentActions = false;
    showProofOfPaymentDoc = false;
    popId: number;
    row = 0;
    attachmentFile: File;
    proof: ProofOfPayment = <ProofOfPayment>{};
    selectAll: boolean;

    sortBy: string;
    sortAscending: boolean;
    previousSortBy: string;

    sortDate: boolean;
    sortComments: boolean;
    sortLawfirm: boolean;
    sortReference: boolean;
    sortAmount: boolean;
    sortAllocated: boolean;
    searchTerm: string;
    crumbs: ICrumb[];

    public promptInvoicesEvent: EventEmitter<ProofOfPayment> = new EventEmitter<ProofOfPayment>();
    @ViewChild('inputCheckbox') inputCheckbox: ElementRef;
    @Output() updateProofOfPayment: EventEmitter<any> = new EventEmitter<any>();

    constructor(
    private processPaymentService: ProcessPaymentService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService) {
    } // end constructor()

    ngOnInit() {
        this.crumbs = [
            {
                label: 'PROCESS',
                link: PATH_PROCESS
            },
            {
                label: 'MANAGE PROOF OF PAYMENTS'
            }
        ];
        this.loadingService.showOverlay();
        this.getPayment();
    } // end ngOnInit()

    initProofOfPayment() {
        this.addedProofOfPayment = [];
        this.data.forEach(payment => {
            this.addedProofOfPayment.push({
                data: payment,
                state: {
                    isVisible: true,
                    isSelected: false,
                    isOpen: false
                }
            });
        });
    } // end initProofOfPayment()

    getPayment() {
        // this.data =  
        this.processPaymentService.getProofOfPayment().subscribe({ next: (_data) => {
            this.data = _data;            
        },
        error: (error) => {
            // On error
        },
        complete: () => {
            // On complete
            this.initProofOfPayment();
            this.loadingService.hideOverlay();
        }
    });
    } // end getPayment()

    toggleCheckbox(payment: AddedProofOfPaymentHandle) {
        payment.state.isSelected = !payment.state.isSelected;
        if (this.addedProofOfPayment.find(proof => proof.state.isSelected === true)) {
            this.showDeleteActions = true;
            this.showAttachmentActions = true;
        } else {
            this.showDeleteActions = false;
            this.showAttachmentActions = false;
        }
    } // end toggleCheckbox()

    toggleSelectAll() {
        this.selectAll = !this.selectAll;
        this.addedProofOfPayment.forEach(proof => {
            proof.state.isSelected = this.selectAll;
            this.showDeleteActions = this.selectAll;
            this.showAttachmentActions = this.selectAll;
        });
    } // end toggleSelectAll()

    getDateFormet(value: Date): string {
        return moment(value).format('YYYY-MM-DD');
    } // end getDateFormet()

    numberConversion(value: number): string {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } // end numberConversion()

    openModel() {
        this.proof = Object.assign({}, DEFAULT_PROOF_OF_PAYMENT);
        this.isVisible = true;
    } // end openModel()

    onEditPOP(proofOfPayment: ProofOfPayment) {
        this.proof = Object.assign({}, DEFAULT_PROOF_OF_PAYMENT);
        this.proof = proofOfPayment;
        this.isVisible = true;
    } // end onEditPOP()

   onCloseModel($event) {
        let closingEvent = $event;
        this.isVisible = false;
        this.processPaymentService.getProofOfPayment().subscribe({ next: (_data) => {
            this.data = _data;
            },
            error: (error) => {
                // On error
            },
            complete: () => {
                // On complete
                this.initProofOfPayment();
                this.loadingService.hideOverlay();
        
            if (closingEvent && closingEvent.Id > 0) {
                this.row = $event;
                this.onRowClick($event);
            } else if (Number(closingEvent)) {
            const data = this.addedProofOfPayment.filter(d => d.data.Id === $event)[0];
            this.row = data.data.Id;
            this.onRowClick(data.data);
            closingEvent = '';
                }
            }
        });
    } // edn onCloseModel()

    toggleSort(sortBy: string) {
        this.sortBy = sortBy;
        if (this.sortBy === this.previousSortBy) {
            this.sortAscending = !this.sortAscending;
        }

        switch (this.sortBy) {
            case 'date':
                this.addedProofOfPayment.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = true;
                        this.sortComments = false;
                        this.sortLawfirm = false;
                        this.sortReference = false;
                        this.sortAmount = false;
                        this.sortAllocated = false;
                        return a.data.Date.toUpperCase().localeCompare(b.data.Date.toUpperCase());
                    }
                    this.sortDate = false;
                    return b.data.Date.toUpperCase().localeCompare(a.data.Date.toUpperCase());
                });
                break;
            case 'comments':
                this.addedProofOfPayment.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortComments = true;
                        this.sortLawfirm = false;
                        this.sortReference = false;
                        this.sortAmount = false;
                        this.sortAllocated = false;
                        return a.data.Description.toUpperCase().localeCompare(b.data.Description.toUpperCase());
                    }
                    this.sortComments = false;
                    return b.data.Description.toUpperCase().localeCompare(a.data.Description.toUpperCase());
                });
                break;
            case 'lawfirm':
                this.addedProofOfPayment.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortComments = false;
                        this.sortLawfirm = true;
                        this.sortReference = false;
                        this.sortAmount = false;
                        this.sortAllocated = false;
                        return a.data.LawFirm.toUpperCase().localeCompare(b.data.LawFirm.toUpperCase());
                    }
                    this.sortLawfirm = false;
                    return b.data.LawFirm.toUpperCase().localeCompare(a.data.LawFirm.toUpperCase());
                });
                break;
            case 'reference':
                this.addedProofOfPayment.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortComments = false;
                        this.sortLawfirm = false;
                        this.sortReference = true;
                        this.sortAmount = false;
                        this.sortAllocated = false;
                        return a.data.Reference.toUpperCase().localeCompare(b.data.Reference.toUpperCase());
                    }
                    this.sortReference = false;
                    return b.data.Reference.toUpperCase().localeCompare(a.data.Reference.toUpperCase());
                });
                break;
            case 'amount':
                this.addedProofOfPayment.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortComments = false;
                        this.sortLawfirm = false;
                        this.sortReference = false;
                        this.sortAmount = true;
                        this.sortAllocated = false;
                        return a.data.Amount - b.data.Amount;
                    }
                    this.sortAmount = false;
                    return b.data.Amount - a.data.Amount;
                });
                break;
            case 'allocated':
                this.addedProofOfPayment.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortComments = false;
                        this.sortLawfirm = false;
                        this.sortReference = false;
                        this.sortAmount = false;
                        this.sortAllocated = true;
                        return a.data.Allocated.toUpperCase().localeCompare(b.data.Allocated.toUpperCase());
                    }
                    this.sortAllocated = false;
                    return b.data.Allocated.toUpperCase().localeCompare(a.data.Allocated.toUpperCase());
                });
            break;
        }
        this.previousSortBy = this.sortBy;
    } // end toggleSort()

    performSearch() {
        if (this.searchTerm) {
            this.addedProofOfPayment.forEach(pop => {
                if (
                    !(pop.data.Description.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(pop.data.LawFirm.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(pop.data.Reference.toString().match(new RegExp(this.searchTerm, 'gi')))
                ) {
                    pop.state.isVisible = false;
                } else {
                    pop.state.isVisible = true;
                }
            });
        } else {
            this.addedProofOfPayment.forEach(pop => {
                pop.state.isVisible = true;
            });
        }
    } // end performSearch()

    clearSearch() {
        this.searchTerm = '';
        this.performSearch();
    } // end clearSearch()

    // onChangeCheckBox(id: number, isChecked: boolean) {
    //     if (isChecked) {
    //         this.proofOfPaymentSelected.push(id);
    //     } else {
    //         const index = this.proofOfPaymentSelected.indexOf(id);
    //         this.proofOfPaymentSelected.splice(index, 1);
    //     }

    //     if (this.proofOfPaymentSelected.length > 0) {
    //         this.showDeleteActions = true;
    //         this.showAttachmentActions = true;
    //     } else {
    //         this.showDeleteActions = false;
    //         this.showAttachmentActions = false;
    //     }

    //     if (this.proofOfPaymentSelected.length === 1) {
    //         this.proofOfPaymentId = this.proofOfPaymentSelected[0];
    //         this.showAttachmentActions = true;
    //     } else {
    //         this.proofOfPaymentId = 0;
    //         this.showAttachmentActions = false;
    //     }
    // } // end onChangeCheckBox()

    onClickDelete() {
        if (this.showDeleteActions) {
            this.showDeleteProofOfPayments = true;
        }
    } // end onClickDelete()

    onHideDeleteProofOfPayments() {
        this.showDeleteProofOfPayments = false;
    } // end onHideDeleteProofOfPayments()

    onDeletedProofOfPayments() {
        this.loadingService.showOverlay();
        const snack: Snack = {
            label: 'Deleting Proof of Payment',
            action: null
        };
    
        const deleteRequests = this.addedProofOfPayment
            .filter(proofOfPaymentId => proofOfPaymentId.state.isSelected === true)
            .map(proofOfPaymentId => this.processPaymentService.deleteProofOfPayment(proofOfPaymentId.data.Id));
    
        if (deleteRequests.length > 0) {
            forkJoin(deleteRequests).subscribe({
                next: (deleted) => {
                    // This block will only run if all deletions succeed
                    snack.label = 'Deleted Proof of Payment.';
                    snack.type = SnackType.SUCCESS;
                    this.updateProofOfPayment.emit('Updated');
                },
                error: (error) => {
                    // This block will run if any deletion fails
                    this.loadingService.hideOverlay();
                    snack.label = 'Something went wrong: ' + error;
                    snack.type = SnackType.ERROR;
                    this.snackbarsService.dismiss().make(snack).show();
                },
                complete: () => {
                    // This block runs once after all delete requests are complete, whether successful or not
                    this.showDeleteProofOfPayments = false;
                    this.getPayment();
                    this.row = 0;
                    this.onRowClick(0);
                    this.showDeleteActions = false;
                    this.showAttachmentActions = false;
                    this.proofOfPaymentSelected = [];
                    this.inputCheckbox.nativeElement.checked = false;
                    this.snackbarsService.dismiss().make(snack).show();
                    this.loadingService.hideOverlay();
                }
            });
        } else {
            // If there are no selected items, show a message or handle accordingly
            snack.label = 'No Proof of Payment selected.';
            snack.type = SnackType.WARNING;
            this.snackbarsService.dismiss().make(snack).show();
            this.loadingService.hideOverlay();
        }
    } // end ()

    // onDeletedProofOfPayments() {
    //     this.loadingService.showOverlay();
    //     const snack: Snack = {
    //         label: 'Deleting Proof of Payment',
    //         action: null
    //     };
    //     // try {
    //         // for (let proofOfPaymentId of this.proofOfPaymentSelected) {

    //             this.addedProofOfPayment.forEach(proofOfPaymentId => {
    //             if (proofOfPaymentId.state.isSelected === true) {
    //                  this.processPaymentService.deleteProofOfPayment(proofOfPaymentId.data.Id).subscribe({ next: (deleted) => {
    //                     // On next
    //                 },
    //                 error: (error) => {
    //                     // On error
    //                     this.loadingService.hideOverlay();
    //                     snack.label = 'Something went wrong: ', + error;
    //                     snack.action = null;
    //                     snack.type = SnackType.ERROR;
    //                     this.snackbarsService.dismiss().make(snack).show();
    //                 },
    //                 complete: () => {
    //                     // On complete
    //                     this.showDeleteProofOfPayments = false;
    //                     this.getPayment();
    //                     this.row = 0;
    //                     this.onRowClick(0);
    //                     // this.promptInvoicesEvent.next();
    //                     this.showDeleteActions = false;
    //                     this.showAttachmentActions = false;
    //                     this.proofOfPaymentSelected = [];
    //                     this.inputCheckbox.nativeElement.checked = false;
    //                     snack.label = 'Deleted Proof of Payment.';
    //                     snack.type = SnackType.SUCCESS;
    //                     this.updateProofOfPayment.emit('Updated');
    //                     this.snackbarsService.dismiss().make(snack).show();
    //                     this.loadingService.hideOverlay();
    //                 }
    //             });
    //             }
    //         });

    //     // } catch (e) {
    //     //     snack.label = 'Something went wrong???';
    //     //     snack.action = null;
    //     //     snack.type = SnackType.ERROR;
    //     //     this.snackbarsService.dismiss().make(snack).show();
    //     // }
    // } // end onDeletedProofOfPayments()


    promptAttachment() {
        if (this.showAttachmentActions) {
            jQuery('#addAttachmentUpload').val(null);
            jQuery('#addAttachmentUpload').trigger('click');
        }
    } // end promptAttachment()

    attachmentChange(e) {

        const snack: Snack = {
            label: 'Add Attachment to Proof of Payment',
            action: null
        };
        try {
            if (this.showAttachmentActions) {
                this.attachmentFile = e.target.files[0];
                const formData = new FormData();
                if (this.attachmentFile) {
                    formData.append('ProofDocument', this.attachmentFile);
                }
                if (this.attachmentFile && this.proofOfPaymentId) {
                    this.processPaymentService.postProofDocumentAttachment(this.proofOfPaymentId, formData).subscribe({ next: (_data) => {}});
                }

                this.getPayment();
            }
            snack.label = 'Selected Attachment uploaded to Proof of Payment.';
            snack.type = SnackType.SUCCESS;
            this.snackbarsService.dismiss().make(snack).show();

        } catch (e) {
            snack.label = 'Something went wrong';
            snack.action = null;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
        }

    } // end attachmentChange()

    getAllocatedColor(value: string): string {
        if (value === 'None') {
            return '#cf624f';
        } else if (value === 'Fully') {
            return '#8abd37';
        } else if (value === 'Partial') {
            return '#e5c100';
        }
    } // end getAllocatedColor()

    onRowClick(val: any) {
        this.row = 0;
        if (val.Id !== 0) {
            this.row = val.Id;
            this.promptInvoicesEvent.next(val);            
            this.updateProofOfPayment.emit('Updated');
        } else {
            this.promptInvoicesEvent.next(val);
        }
    } // end onRowClick()

    viewProofOfPayment(value: any) {
        this.popId = value.Id;
        this.showProofOfPaymentDoc = true;
    } // end viewProofOfPayment()

    closeModal() {
        // this.popId = 0;
        this.showProofOfPaymentDoc = false;
    } // end closeModal()
}

