<div class="row m-0" *ngIf="isDataLoaded===true">
    <!-- #start On average your customers -->
    <div class="col-md-6">
        <div class="col  widget-container" (click)="onViewMoreReport('customerReportPaymentRating')">

            <div class="row widget1-header text-white" [ngStyle]="{'background-color': averageDayColor}">
                <div class="col-11 header-text">On average your law firms take <b class="b">{{averagePaymentDays}}
                        days</b> to pay
                    once due.
                </div>

                <div *ngIf="averageDayColor !== '#e5c100'" class="col-1 fa-icon"> 
                        <i [class.fa-badge-check]="averageDayColor=='#8abd37'" title="Healthy" *ngIf="averageDayColor=='#8abd37'" class="fal"></i>
                       <i [class.fa-exclamation-triangle]="averageDayColor=='#eb9e02'" title="Caution" *ngIf="averageDayColor=='#eb9e02'" class="fal"></i>
                       <i [class.fa-exclamation-circle]="averageDayColor=='#cf624f'" title="Danger" *ngIf="averageDayColor=='#cf624f'" class="fal"></i>
                    </div>
                    <div *ngIf="averageDayColor == '#e5c100'"  class="col-1 fa-icon">
                        <img alt="neutral" src="./../../../../assets/images/icons/icon-neutral.svg" title="Neutral">
                    </div>
            </div>
            <div class="row widget1-body ">
                <div class="col customer-col mr-10px">
                    <div class="row header">5 best paying law firms</div>
                    <div class="row" style="padding-bottom: 0px !important; padding-top: 5px !important;font-size: 11px"
                        *ngFor="let best of bestCustomers;">
                        <div class="col-8 deptor" [title]="best.debtor">{{best.debtor}}</div>
                        <div class="col-4 text-right">{{getDays(best.averageDaysToPayment)}}</div>
                    </div>
                </div>
                <div class="col customer-col">
                    <div class="row header">5 worst paying law firms</div>
                    <div class="row" *ngFor="let worst of worstCustomers;"
                        style="padding-bottom: 0px !important; padding-top: 5px !important;font-size: 11px">
                        <div class="col-8 deptor" [title]="worst.debtor">{{worst.debtor}}</div>
                        <div class="col-4 text-right">{{getDays(worst.averageDaysToPayment)}}</div>
                    </div>
                </div>
            </div>

            <div class="row col-md-2 offset-md-10 text-right">
                <u>View more ></u>
            </div>

        </div>
    </div>
    <!-- #end On average your customers -->


    <!--#start invoiced or collected targets -->
    <div class="col-md-6" (click)="onViewMoreReport('invoicedCollectedMonthlySummary')">
        <div class="col  widget-container">

            <invoiced-collected-widget [graphHeight]="180" [graphMargin]="{ top: 20, right: 20, bottom: 60, left: 104 }"
                [graphWidth]="637"></invoiced-collected-widget>
        </div>
    </div>
    <!--#end -->
</div>
<!-- second row -->
<div class="row m-0" *ngIf="isDataLoaded===true">
    <!-- #start Days overdue -->
    <div class="col-md-6">
        <div class="col  widget-container" (click)="onViewMoreReport('ageAnalysis')">
            <div class="row  widget3-header text-white" [ngStyle]="{'background-color': agePercentageColor}">
                <div class="col-11 header-text"><b class="b">{{aging98Percentage}}% of your book</b> is overdue.
                </div>
                <div *ngIf="agePercentageColor !== '#e5c100'" class="col-1 fa-icon">
                    <i [class.fa-badge-check]="agePercentageColor==='#8abd37'" title="Healthy" *ngIf="agePercentageColor==='#8abd37'" class="fal"></i>
                       <i [class.fa-exclamation-triangle]="agePercentageColor==='#eb9e02'" title="Caution" *ngIf="agePercentageColor==='#eb9e02'" class="fal"></i>
                       <i [class.fa-exclamation-circle]="agePercentageColor==='#cf624f'" title="Danger" *ngIf="agePercentageColor==='#cf624f'" class="fal"></i>
                </div>
                <div *ngIf="agePercentageColor == '#e5c100'" class="col-1 fa-icon">
                    <img alt="neutral" src="./../../../../assets/images/icons/icon-neutral.svg" title="Neutral">
                </div>
            </div>

            <div class="row widget3-body">

                <div class="table-responsive">
                    <table class="table text-center" style="border: 0;">
                        <thead class="thead">
                            <tr>
                                <th scope="col" class="w-10 box-container"></th>
                                <th scope="col" class="text-white box-container w-18">
                                    <div class="bg-current box-child"
                                        [ngStyle]="{'height': getBarHeight(agingCurrentPercentage) }">
                                        <p
                                            [ngStyle]="{'margin-top': getBarTextTopMargin(agingCurrentPercentage) , 'color': getBarTextColor(agingCurrentPercentage)}">
                                            {{agingCurrentPercentage}}%</p>
                                    </div>
                                </th>
                                <th scope="col" class="text-white box-container w-18">
                                    <div class="bg-30 box-child"
                                        [ngStyle]="{'height': getBarHeight(aging30Percentage) }">
                                        <p
                                            [ngStyle]="{'margin-top': getBarTextTopMargin(aging30Percentage) , 'color': getBarTextColor(aging30Percentage)}">
                                            {{aging30Percentage}}%</p>
                                    </div>
                                </th>
                                <th scope="col" class="text-white box-container w-18">
                                    <div class="bg-60 box-child"
                                        [ngStyle]="{'height': getBarHeight(aging60Percentage) }">
                                        <p
                                            [ngStyle]="{'margin-top': getBarTextTopMargin(aging60Percentage) , 'color': getBarTextColor(aging60Percentage)}">
                                            {{aging60Percentage}}%</p>
                                    </div>
                                </th>
                                <th scope="col" class="text-white box-container w-18">
                                    <div class=" bg-90 box-child"
                                        [ngStyle]="{'height': getBarHeight(aging90Percentage) }">
                                        <p
                                            [ngStyle]="{'margin-top': getBarTextTopMargin(aging90Percentage) , 'color': getBarTextColor(aging90Percentage)}">
                                            {{aging90Percentage}}%</p>
                                    </div>
                                </th>
                                <th scope="col" class="text-white box-container w-18">
                                    <div class="bg-98 box-child"
                                        [ngStyle]="{'height': getBarHeight(aging98Percentage) }">
                                        <p
                                            [ngStyle]="{'margin-top': getBarTextTopMargin(aging98Percentage) , 'color': getBarTextColor(aging98Percentage)}">
                                            {{aging98Percentage}}%</p>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <div class="table-responsive">
                    <table class="table text-right mb-0" style="border: 0;">
                        <thead class="thead">
                            <tr>
                                <th scope="col" class="w-10" style="border-color:#f5f6f6 !important">Days
                                </th>
                                <th scope="col" class="bg-current text-white w-18"
                                    style="border-color:#b9b9b9 !important">
                                    Current
                                </th>
                                <th scope="col" class="bg-30 text-white w-18" style="border-color:#888888 !important">30
                                </th>
                                <th scope="col" class="bg-60 text-white w-18" style="border-color:#7a7a7a !important">60
                                </th>
                                <th scope="col" class="bg-90 text-white w-18" style="border-color:#646464 !important">90
                                </th>
                                <th scope="col" class="bg-98 text-white w-18" style="border-color:#cf624f !important">
                                    Overdue</th>
                            </tr>
                        </thead>
                        <tbody class="tbody">
                            <tr scope="">
                                <td class="w-11" scope="row"><b>Total</b></td>
                                <td class="w-17">
                                    <b>{{agingCurrentTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                                </td>
                                <td class="w-17">
                                    <b>{{aging30Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                                </td>
                                <td class="w-17">
                                    <b>{{aging60Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                                </td>
                                <td class="w-17">
                                    <b>{{aging90Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                                </td>
                                <td class="w-17 text-color">
                                    <b><u>{{aging98Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></b>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>

            <div class="row col-md-2 offset-md-10 text-right">
                <u>View more ></u></div>
        </div>
    </div>
    <!-- #end Days overdue -->


    <!--#start -->
    <div class="col-md-6">
        <div class="col widget-container" (click)="onViewMoreReport('taxLiability')">
           <!-- <div class="row widget4-header text-white" style="background-color: #e5c100; padding: 10px 0px !important; padding-left: 0 !important;">
            <div class="col  widget-container"> -->
                <app-tax-liability-widget [graphHeight]="180" [graphMargin]="{ top: 20, right: 20, bottom: 60, left: 104 }"
                [graphWidth]="637"></app-tax-liability-widget>
            <!-- </div> -->
                <!-- <div class="col-11 header-text" style="padding-top: 10px !important;"><b class="b">COMING SOON</b> - Your action needed widget
                </div> 
                <div class="col-1 fa-icon">
                    <i class="fal fa-info-circle" title="Info."></i>
                </div> -->
            <!-- </div> -->
                <!-- <div class="row widget4-body">
                    <div class="col customer-col mr-10px">
                        <div class="row header">
                            <i class="fa fa fa-wrench" style="font-size: 34px;"></i>
                        </div>
                        <div class="row">
                            <p style="font-size: 14px;"> We are hard at work on your <b>"Action Needed" Widget and it is coming soon.</b>
                            </p>
                            <br/>
                            <ul>
                                <li>Get notifications from Auxcon or your assistant and give instructions to them.</li>
                                <li>View invoices that need your attention.</li>
                            </ul>
                            
                        </div>
                    </div>
                </div> -->
          
                <!-- <div class="row col-md-2 offset-md-10 text-right" style="margin-left: 77%; max-width: 23%">
                    <u>See an example ></u></div> -->
        <!-- </div> -->
    </div>
    <!--#end -->
</div>
<app-image-viewer
    [isVisible]="showSampleModalDialog"
    (closeModal)="onHideSampleModalDialog()"></app-image-viewer>