import { Component, OnInit, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart } from '@angular/router';
import { NavigationService } from '../../../services/navigation/navigation.service';
// import { SubscriptionLike as ISubscription } from 'rxjs';
import { ProcessPaymentService } from '../../../services/process-payment/process-payment.service';
import { PATH_PROOF_OF_PAYMENTS } from '../../../services/appdata/app-config.service';
import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
    selector: 'app-manage-proof-of-payments',
    templateUrl: './manage-proof-of-payments.component.html',
    styleUrls: ['./manage-proof-of-payments.component.scss']
})
export class ManageProofOfPaymentsComponent implements OnInit, OnDestroy, OnChanges {

    showUnHandledPopChangesPrompt: boolean;
    private _subscriptionNavigationStart: ISubscription;
    private _exitRoute: string;
    private _canIgnoreNavigationBlock: boolean;
    data: any = [];
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private navigation: NavigationService,
        private processPaymentService: ProcessPaymentService
    ) {
    } // end constructor()

    get processedPayment(): boolean {
        let hasUnhandlePOP = false;
      const d =  this.data.filter(s => s.ProofOfPaymentLines.length === 0);

      if (d.length > 0) {
        hasUnhandlePOP = true;
      } else {
          hasUnhandlePOP = false;
      }
      return hasUnhandlePOP;
    } // end processedPayment()

    ngOnInit() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });

        this.getProofOfPayments();

        this._subscriptionNavigationStart = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart && this.processedPayment) {
                this._exitRoute = event.url;

                if (location.href.includes(PATH_PROOF_OF_PAYMENTS) && this.processedPayment) {
                    if (!this._canIgnoreNavigationBlock) {
                        this.navigation.canNavigate = false;
                        this.showUnHandledPopChangesPrompt = true;
                    } else {
                        this.navigation.canNavigate = true;
                    }
                }
            }
        });
    } // end ngOnInit()

    ngOnChanges(changes: SimpleChanges) {
    } // end ngOnChanges

    getProofOfPayments() {
        this.processPaymentService.getProofOfPayment().subscribe({next: (_data) => {
            this.data = _data;            
        }});
    } // end getProofOfPayment()

    ngOnDestroy() {
        this._subscriptionNavigationStart.unsubscribe();
    } // end ngOnDestroy()

    onHideUnHandledPopChangesPrompt() {
        this.showUnHandledPopChangesPrompt = false;
    } // end onHideUnHandledPopChangesPrompt()

    leavePage() {
        this._canIgnoreNavigationBlock = true;
        this.onHideUnHandledPopChangesPrompt();
        setTimeout((thisObj) => {
            thisObj.router.navigate([this._exitRoute.replace('/', '')]);
        }, 300, this);
    } // end leavePage()
}
