<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div id="ReportPage" class="container-fluid">
  <!-- <h2 class="page-title">{{title}}</h2> -->
  <nav class="report-nav">
    <a class="tab" *ngIf="canViewFinanctialReport || AuxiliumOhnaPermissions" [class.active]="reportType === 'practiceManagementReports'"
      (click)="togglePanel('practiceManagementReports')">My Practice</a>
    <a class="tab" [class.active]="reportType === 'businessReports'" (click)="togglePanel('businessReports')">Business
      Report</a>
    <a class="tab" *ngIf="canViewFinanctialReport" [class.active]="reportType === 'financialReports'"
      (click)="togglePanel('financialReports')">Financial Report</a>
      <a class="tab" *ngIf="canViewFinanctialReport && (!isInternalLawfirm) && !isExternalAttorney && (!isAttorney)" [class.active]="reportType === 'industryReport'"
        (click)="togglePanel('industryReport')">Industry Reports</a>
  </nav>

  <div class="row reports-options">
    <!-- <div class="column-report-type">
  <div class="row reports-options m-0">
    <div class="col-sm-4">
      <label for="report-type">Report Type:</label>
      <select class="w-75" (change)="togglePanel($event.target.value)" [(ngModel)]="reportType">
        <option value="businessReports">Business Reports</option>
        <option *ngIf="canDisplayFinancialReport" value="financialReports">Financial Reports</option>
        <option *ngIf="isAttorney || isExternalAttorney || isInternalLawfirm" value="liveStatement">Live Statement</option>
        <option value="practiceManagementReports">Practice Management</option>
      </select>
    </div> -->
    <!--#end .column-report-type -->
    <div class="column-report-name" *ngIf="!showLiveStatementReports">
      <label for="report-type" class="report-name">Report:</label>
      <select class="w-75" (change)="viewReport()" [(ngModel)]="reportName">
        <!-- <option value="null">Choose a report</option> -->
        <!-- Financial Reports -->
        <option *ngIf="reportType === 'financialReports'" value="financialSummaryViewReport">Financial Summary View
          Report</option>
        <option *ngIf="reportType === 'financialReports'" value="invoiceHistory">Invoice History Report</option>
        <option *ngIf="reportType === 'financialReports'" value="invoiceHistoryByLawfirm">Invoice History By Law Firm
          Report</option>
        <option *ngIf="reportType === 'financialReports'" value="recon">Reconciliation History Report</option>
        <option *ngIf="reportType === 'financialReports'" value="invoicePaid">VAT on Invoice Paid Report</option>
        <option *ngIf="reportType === 'financialReports'" value="disbursement">98 Day Disbursement Report</option>
        <!-- <option *ngIf="reportType === 'financialReports'" value="monthlyMeeting">Monthly Meeting Report</option> -->
        <option *ngIf="reportType === 'financialReports'" value="practiceAssessment">Practice Assessment Report</option>
        <!-- Business Reports -->
        <option *ngIf="reportType === 'businessReports'" value="workInProgress">Work In Progress Report</option>
        <option *ngIf="reportType === 'businessReports'" value="outstanding">Outstanding Invoice Report</option>
        <option *ngIf="reportType === 'businessReports'" value="calendarAction">Calendar Action Report</option>
        <option *ngIf="reportType === 'businessReports'" value="deletedInvoice">Deleted Invoice Report</option>
        <option *ngIf="reportType === 'businessReports'" value="statementOfAccount">Statement of Account Report</option>
        <!-- Practice Management Reports -->
        <option *ngIf="reportType === 'practiceManagementReports' && canViewFinanctialReport" value="practiceSummary">Practice Summary</option>
        <option *ngIf="reportType === 'practiceManagementReports'" value="ageAnalysis">Age Analysis</option>
        <option *ngIf="reportType === 'practiceManagementReports' && canViewFinanctialReport" value="invoicedCollectedMonthlySummary">Invoiced &
          Collected Monthly Summary Report</option>
        <option *ngIf="reportType === 'practiceManagementReports'" value="allCustomersReport">All Customers Report
        </option>
        <option *ngIf="reportType === 'practiceManagementReports'" value="individualCustomerReport"> Individual
          Customer Report</option>
        <option *ngIf="reportType === 'practiceManagementReports' && canViewFinanctialReport" value="customerReportPaymentRating">Customer Report -
          Payment Rating</option>
      </select>
    </div>
    <!--#end .column-report-name -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'invoicedCollectedMonthlySummary'">
      <label for="date" class="fromTo-date">From</label>
      <input type="text" name="fromDate" class="input-date" #_fromDate="bsDatepicker" bsDatepicker
        placeholder="2010-01-01" placement="bottom" [(ngModel)]="reportParameters.fromDate"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" (bsValueChange)="onPracticeManagementFromDateValueChange($event)">
      <i class="fa fa-calendar" (click)="_fromDate.toggle()" [attr.aria-expanded]="_fromDate.isOpen"></i>
    </div>
    <!-- <p *ngIf="isInvalidPracticeManagementFromToDate" for="fromDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!-- <div class="column-report-date col-fs-4 clickable" *ngIf="reportType === 'practiceManagementReports'" (click)="getDebtorProgressReport()">
      <label for="date" class="fromTo-date" style="width: 147px !important;">Debot Progress</label>      
      <i class="fa fa-file"></i>
    </div> -->
    <!--#end .column-report-date -->
    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'invoicedCollectedMonthlySummary'">
      <label for="date" class="fromTo-date">To</label>
      <input type="text" class="input-date" #_toDate="bsDatepicker" bsDatepicker placeholder="2010-01-01"
        placement="bottom" [(ngModel)]="reportParameters.toDate" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        (bsValueChange)="onPracticeManagementToDateValueChange($event)">
      <i class="fa fa-calendar" (click)="_toDate.toggle()" [attr.aria-expanded]="_toDate.isOpen"></i>
    </div>
    <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'invoicedCollectedMonthlySummary'"
      (click)="onClickViewPracticeManagementReport()"></p>
    <!-- <p *ngIf="isInvalidPracticeManagementFromToDate" class="text-danger col-fs-2">To Date cannot be less than From Date.</p> -->
    <!--#end .column-report-date -->

    <!-- CALENDAR ACTION REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'calendarAction'">
      <label for="date" class="fromTo-date">From</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_fromDate="bsDatepicker" placeholder="2010-01-01"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate = $event.target.value" (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" placement="bottom">
      <i class="fa fa-calendar" (click)="_fromDate.toggle()" [attr.aria-expanded]="_fromDate.isOpen"></i>
    </div>
    <!--#end .column-report-date From date -->
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'calendarAction'">
      <label for="date" class="fromTo-date">To</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_toDate="bsDatepicker" placeholder="2010-01-01"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'" (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" placement="bottom">
      <i class="fa fa-calendar" (click)="_toDate.toggle()" [attr.aria-expanded]="_toDate.isOpen"></i>
    </div>
    <!--#end .column-report-date  To Date -->
    <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'calendarAction'" (click)="onClickViewReport()"></p>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->

    <!-- INVOICE HISTORY REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'invoiceHistory'">
      <label for="date" class="fromTo-date">From</label>
      <input type="text" id="text" class="input-date" #_fromDate="bsDatepicker" bsDatepicker placeholder="2010-01-01"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value" (bsValueChange)="onFromDateValueChange($event)"
        placement="bottom" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
      <i class="fa fa-calendar" (click)="_fromDate.toggle()" [attr.aria-expanded]="_fromDate.isOpen"></i>
    </div>
    <!--#end .column-report-date From date -->
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'invoiceHistory'">
      <label for="date" class="fromTo-date">To</label>
      <input type="text" id="text" class="input-date" #_toDate="bsDatepicker" bsDatepicker placeholder="2010-01-01"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'" (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)" placement="bottom" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
      <i class="fa fa-calendar" (click)="_toDate.toggle()" [attr.aria-expanded]="_toDate.isOpen"></i>
    </div>
    <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'invoiceHistory'" (click)="onClickViewReport()"></p>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date  To Date -->

    <!-- INVOICE HISTORY BY LAWFIRM REPORT PARAMETERS -->
    <div class="column-report-debtor badge" *ngIf="_reportName === 'invoiceHistoryByLawfirm'">
      <label *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" for="" class="debtor-label">Law Firms:</label>
      <label *ngIf="isAssistantToLawfirm || isAttorney || isLawfirm" for="" class="debtor-label">Clients:</label>
      <input [(ngModel)]="selectedLawfirm" [typeahead]="AllSelectedFirm" typeaheadOptionField="FullName"
        [typeaheadSingleWords]="false" (input)="onInput($event)" (focus)="onFocus()" class="form-control"
        placeholder="Select or start typing" (typeaheadOnSelect)="onLawfirmSelect($event)" [typeaheadOptionsLimit]="50"
        typeaheadScrollable=true typeaheadOptionsInScrollableView="10" [typeaheadMinLength]="0" [dropup]="false">
      <!-- <p *ngIf="isInvalidSelectedLawfirm" class="text-danger">Please select Law firm.</p> -->
    </div>
    <!--#end .column-report-type -->

    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'invoiceHistoryByLawfirm'">
      <label for="date" class="fromTo-date">From</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_fromDate="bsDatepicker" placeholder="2010-01-01"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value" (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" placement="bottom">
      <i class="fa fa-calendar" (click)="_fromDate.toggle()" [attr.aria-expanded]="_fromDate.isOpen"></i>
    </div>
    <!--#end .column-report-date From date -->
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'invoiceHistoryByLawfirm'">
      <label for="date" class="fromTo-date">To</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_toDate="bsDatepicker" placeholder="2010-01-01"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'" (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" placement="bottom">
      <i class="fa fa-calendar" (click)="_toDate.toggle()" [attr.aria-expanded]="_toDate.isOpen"></i>
    </div>
    <!--#end .column-report-date  To Date -->
    <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'invoiceHistoryByLawfirm'"
      (click)="onClickViewReport()"></p>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->

    <!-- RECONCILIATION HISTORY REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'recon'">
      <label for="date" class="fromTo-date">From</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_fromDate="bsDatepicker" placeholder="2010-01-01"
        placement="bottom" [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value" (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
      <i class="fa fa-calendar" (click)="_fromDate.toggle()" [attr.aria-expanded]="_fromDate.isOpen"></i>
    </div>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date From date -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'recon'">
      <label for="date" class="fromTo-date">To</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_toDate="bsDatepicker" placeholder="2010-01-01"
        placement="bottom" [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value" (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
      <i class="fa fa-calendar" (click)="_toDate.toggle()" [attr.aria-expanded]="_toDate.isOpen"></i>
    </div>
    <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'recon'" (click)="onClickViewReport()"></p>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date  To Date -->


    <!-- VAT ON INVOICE PAID REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'invoicePaid'">
      <label for="date" class="fromTo-date">From</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_fromDate="bsDatepicker" placeholder="2010-01-01"
        placement="bottom" [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value" (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
      <i class="fa fa-calendar" (click)="_fromDate.toggle()" [attr.aria-expanded]="_fromDate.isOpen"></i>
    </div>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date From date -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'invoicePaid'">
      <label for="date" class="fromTo-date">To</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_toDate="bsDatepicker" placeholder="2010-01-01"
        placement="bottom" [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value" (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
      <i class="fa fa-calendar" (click)="_toDate.toggle()" [attr.aria-expanded]="_toDate.isOpen"></i>
    </div>
    <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'invoicePaid'" (click)="onClickViewReport()"></p>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date  To Date -->


    <!-- 98 DAYS DISBURSEMENT REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'disbursement'">
      <label for="date" class="fromTo-date">From</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_fromDate="bsDatepicker" placeholder="2010-01-01"
        placement="bottom" [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value" (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
      <i class="fa fa-calendar" (click)="_fromDate.toggle()" [attr.aria-expanded]="_fromDate.isOpen"></i>
    </div>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date From date -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'disbursement'">
      <label for="date" class="fromTo-date">To</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_toDate="bsDatepicker" placeholder="2010-01-01"
        placement="bottom" [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value" (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
      <i class="fa fa-calendar" (click)="_toDate.toggle()" [attr.aria-expanded]="_toDate.isOpen"></i>
    </div>
    <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'disbursement'" (click)="onClickViewReport()"></p>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date  To Date -->

    <!-- MONTHLY MEETING REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'monthlyMeeting'">
      <label for="date" class="fromTo-date">From</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_fromDate="bsDatepicker" placeholder="2010-01-01"
        placement="bottom" [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value" (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
      <i class="fa fa-calendar" (click)="_fromDate.toggle()" [attr.aria-expanded]="_fromDate.isOpen"></i>
    </div>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date From date -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'monthlyMeeting'">
      <label for="date" class="fromTo-date">To</label>
      <input type="text" id="text" class="input-date" bsDatepicker #_toDate="bsDatepicker" placeholder="2010-01-01"
        placement="bottom" [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value" (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
      <i class="fa fa-calendar" (click)="_toDate.toggle()" [attr.aria-expanded]="_toDate.isOpen"></i>
    </div>
    <!--#end .column-report-date  To Date -->
    <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'monthlyMeeting'" (click)="onClickViewReport()"></p>
    <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->


    <!-- FINANCIAL SUMMARY VIEW REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'financialSummaryViewReport'">
      <label for="date">From Year</label>
      <!-- <div width="100%"> -->
      <select name="fromYear" id="fromYear" class="input-date" [(ngModel)]="reportParameters.fromYear">
        <option>2013</option>
        <option>2014</option>
        <option>2015</option>
        <option>2016</option>
        <option>2017</option>
        <option>2018</option>
        <option>2019</option>
        <option>2020</option>
        <option>2021</option>
        <option>2022</option>
        <option>2023</option>
        <option>2024</option>
        <option>2025</option>
        <option>2026</option>
        <option>2027</option>
        <option>2028</option>
        <option>2029</option>
        <option>2030</option>
        <option>2031</option>
        <option>2032</option>
        <option>2033</option>
      </select>
      <!-- </div> -->
    </div>
    <!-- <p *ngIf="isInvalidFromToYear" class="text-danger col-fs-1">From Year cannot be greater than To Year.</p> -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'financialSummaryViewReport'">
      <label for="date">From Month</label>
      <!-- <div> -->
      <select name="fromMonth" id="fromMonth" class="input-date" [(ngModel)]="reportParameters.fromMonth">
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>
      <!-- </div> -->
    </div>
    <!-- <p *ngIf="isInvalidFromToMonth" class="text-danger col-fs-2">From Month cannot be greater than To Month on the same year.</p> -->
    <!--#end .column-report-date From date -->

    <div class="column-report-date col-fs-3" *ngIf="_reportName === 'financialSummaryViewReport'">
      <label for="date">To Year</label>
      <!-- <div width="100%"> -->
      <select name="toYear" id="toYear" class="input-date" [(ngModel)]="reportParameters.toYear">
        <option>2013</option>
        <option>2014</option>
        <option>2015</option>
        <option>2016</option>
        <option>2017</option>
        <option>2018</option>
        <option>2019</option>
        <option>2020</option>
        <option>2021</option>
        <option>2022</option>
        <option>2023</option>
        <option>2024</option>
        <option>2025</option>
        <option>2026</option>
        <option>2027</option>
        <option>2028</option>
        <option>2029</option>
        <option>2030</option>
        <option>2031</option>
        <option>2032</option>
        <option>2033</option>
      </select>
      <!-- </div> -->
    </div>
    <!-- <p *ngIf="isInvalidFromToYear" class="text-danger col-fs-3">From Year cannot be greater than To Year.</p> -->

    <div class="column-report-date col-fs-4" *ngIf="_reportName === 'financialSummaryViewReport'">
      <label for="date">To Month</label>
      <!-- <div> -->
      <select name="toMonth" id="toMonth" class="input-date" (change)="selectedToMonth($event)"
        [(ngModel)]="reportParameters.toMonth">
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>
      <!-- </div> -->
    </div>
    <!-- <p *ngIf="isInvalidFromToMonth" class="text-danger col-fs-4">From Month cannot be greater than To Month on the same year.</p> -->
    <!--#end .column-report-date  To Date -->
    <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'financialSummaryViewReport'"
      (click)="selectedToMonth($event)"></p>

    <!-- <div class="column-report-debtor badge" *ngIf="_reportName === 'individualCustomerReport'"> -->
    <div class="column-report-debtor badge" *ngIf="_reportName === 'individualCustomerReport'">
      <label *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" for="" class="debtor-label">Law Firms:</label>
      <label *ngIf="isAssistantToLawfirm || isAttorney || isLawfirm" for="" class="debtor-label">Clients:</label>
      <!-- <select (change)=" onDebtorValueChange($event.target.value)" [(ngModel)]="_debtorId">
        <option *ngFor="let i of debtorData;" value="{{i.contactID}}">{{i.fullName}}</option>
      </select>  -->
      <input [(ngModel)]="debtorName" (blur)="onDebtorBlur()" [typeahead]="debtorData" typeaheadOptionField="fullName"
        [typeaheadSingleWords]="false" class="form-control" placeholder="Select or start typing"
        [typeaheadOptionsLimit]="100" typeaheadScrollable="true" typeaheadOptionsInScrollableView="20"
        [typeaheadMinLength]="0" (typeaheadOnSelect)="onDebtorSelect($event)" />
    </div>
    <!--#end .column-report-type -->


    <div class="column-report-debtor badge" *ngIf="_reportName === 'statementOfAccount'">
      <label *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" for="" class="debtor-label">Law Firms:</label>
      <label *ngIf="isAssistantToLawfirm || isAttorney || isLawfirm" for="" class="debtor-label">Clients:</label>
      <!-- <select (change)=" onDebtorValueChange($event.target.value)" [(ngModel)]="_debtorId">
        <option *ngFor="let i of debtorData;" value="{{i.contactID}}">{{i.fullName}}</option>
      </select>  -->
      <input [(ngModel)]="selectedLawfirm" [typeahead]="AllSelectedFirm" typeaheadOptionField="FullName"
        [typeaheadSingleWords]="false" (input)="onInput($event)" (focus)="onFocus()" class="form-control"
        placeholder="Select or start typing" (typeaheadOnSelect)="onSelect($event)" [typeaheadOptionsLimit]="50"
        typeaheadScrollable=true typeaheadOptionsInScrollableView="10" [typeaheadMinLength]="0" [dropup]="false">
    </div>
    <!--#end .column-report-type -->

    <!-- <div></div> -->
  </div>

  <div *ngIf="!showLiveStatementReports" class="flex-container">

    <!-- Practice Summary-->
    <div style="width:100%; margin-top:3px;" *ngIf="_reportName === 'practiceSummary'">
      <app-practice-summary (onViewMoreClick)=" receiveCustomerReport($event)"></app-practice-summary>
    </div>

    <!-- Invoiced & Collected Monthly Summary Report -->
    <div style="width:100%;margin-top:3px;" *ngIf="_reportName === 'invoicedCollectedMonthlySummary'">
      <invoiced-collected-summary-report [graphMargin]="{ top: 20, right: 20, bottom: 70, left: 80 }" [graphWidth]="960"
        [graphHeight]="400"></invoiced-collected-summary-report>
    </div>
    <!-- Age Analysis -->
    <div style="width:100%; margin-top:3px;" *ngIf="_reportName === 'ageAnalysis'">
      <age-analysis-report (onRowClick)="receiveAllCustomer($event)"></age-analysis-report>
    </div>
    <!-- All Customers Report -->
    <div style="width:100%; margin-top:3px;" *ngIf="_reportName === 'allCustomersReport'">
      <app-all-customers-report (onRowClick)="receiveDebtorID($event)" [(activeTab)]="_defaultTab">
      </app-all-customers-report>
    </div>
    <!-- Customer Report - Payment Rating -->
    <div style="width:100%; margin-top:3px;" *ngIf="_reportName === 'customerReportPaymentRating'">
      <app-customer-report-payment-rating (onRowClick)="receiveDebtorID($event)"></app-customer-report-payment-rating>
    </div>

    <!-- Customer Report - Payment Rating -->
    <div style="width:100%; margin-top:3px;" *ngIf="_reportName === 'individualCustomerReport'">
      <app-individual-customer-report></app-individual-customer-report>
    </div>

    <!-- Report area -->
    <div style="width:100%; height:900px" *ngIf="reportType !== 'practiceManagementReports'">
      <!-- <app-report-viewer [reportUrl]="reportUrl"></app-report-viewer> -->
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
    </div>
    <!-- <div
      *ngIf="isPracticeManager && _reportName === 'allCustomersReport'"
      class="well footer-buttons">
      <button
        class="btn btn-success"
        (click)="onExcelGenerator()">Generate Excel Report</button>
    </div> #end .well .footer-buttons -->
  </div><!-- #flex-container -->
  <app-age-analysis [data]="ageAnalysisData" *ngIf="showLiveStatementReports"></app-age-analysis>
  <app-live-statement [data]="statementByAdvocate" *ngIf="showLiveStatementReports"></app-live-statement>

  <app-alert title="PLEASE SEARCH AND SELECT LAW FIRM" message="Please select a Law Firm from predefined list."
    [isVisible]="showDebtorErrorPrompt" (cancel)="onHideDebtorErrorPrompt()" (confirm)="onHideDebtorErrorPrompt()">
  </app-alert>

  <app-alert [title]="erroMessageTitle" [message]="errorMessage" [isVisible]="showErrorMessageModelPrompt"
    (confirm)="onHideErrorMessageModelPrompt()" (cancel)="onHideErrorMessageModelPrompt()"></app-alert>